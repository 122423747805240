import * as React from "react"
import { galleryContent as GalleryContent } from "../components/GalleryContent"

export default function GalleryLightbox({tab, photo, setActivePhoto}) {

	const numPhotos = GalleryContent[tab].photos.length
	console.log('ukupno fotki: ', numPhotos)
	console.log('photo: ', photo)

	const handleKeyDown = (e) => {
		if (e.key === 'Escape') {
			setActivePhoto(false)
		}
		if (e.key === 'ArrowRight' && (photo + 1) < numPhotos) {
			setActivePhoto(photo += 1)
		}
		if (e.key === 'ArrowLeft' && photo > 0) {
			setActivePhoto(photo -= 1)
		}
	}

  return (
		<div onKeyDown={handleKeyDown} tabIndex={-1} className="slick-lightbox" style={{background: "rgba(0,0,0,.8)"}} onClick={() => setActivePhoto(false)}>
			<div className="slick-lightbox-inner">
				<div className="slick-lightbox-slick slick-caption-dynamic slick-initialized slick-slider">

					{ photo > 0 ?
					<button onClick={(e) => { setActivePhoto(photo -= 1); e.stopPropagation();}} type="button" data-role="none" className="slick-prev slick-arrow" aria-label="Previous">Previous</button>
					: '' }

					<div aria-live="polite" className="slick-list draggable">
						<div className="slick-track" style={{opacity: '1', width: `${100 * numPhotos}%`, transform: `translate3d(-${(100 / numPhotos) * photo}%, 0px, 0px)`, transition: 'all 0.7s'}} role="listbox">

							{ GalleryContent[tab].photos.map((photo) => (
								<div className="slick-lightbox-slick-item slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" style={{width: `${100/numPhotos}%`, height: '100vh'}} tabindex="-1">
									<div className="slick-lightbox-slick-item-inner" style={{maxHeight: '90vh'}}>
										<img onClick={(e) => { e.preventDefault(); e.stopPropagation();}} alt="" className="slick-lightbox-slick-img" src={photo.replace("/crop","")} style={{maxHeight: '90vh'}} />
									</div>
								</div>
							))}

						</div>
					</div>

					{ (photo + 1) < numPhotos ?
						<button onClick={(e) => { setActivePhoto(photo += 1); e.stopPropagation();}} type="button" data-role="none" className="slick-next slick-arrow" aria-label="Next">Next</button>
					: '' }

				</div>
				<div><div></div></div>
				<button type="button" className="slick-lightbox-close" onClick={(e) => { e.preventDefault(); setActivePhoto(false)}}></button>
			</div>
		</div>
  )
}

