import * as React from "react"

export default function GalleryPhoto({photoIndex, photo, setActivePhoto}) {
  return (
    <div className="col-12 col-sm-6 col-md-4">
      <a href={photo} onClick={(e) => { e.preventDefault(); setActivePhoto(photoIndex)}} className="square-photo-wrap gallery-nav-img-wrap_link">
        <span className="square-photo">
          <img src={photo} alt="" />
          <span className="overlay gallery-overlay">
    {/*
            <span className="small-title">All Eiusmod tempor didunt</span>
            */}
            <span className="icon-plus decoration-icon icomoon"></span>
          </span>
        </span>

        <span className="border-animation-top"></span>
        <span className="border-animation-left"></span>

        <span className="border-animation-bottom"></span>
        <span className="border-animation-right"></span>
      </a>
    </div>
  )
}
