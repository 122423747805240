export const galleryContent = [
  {
    album: 'casica-ukusa',
		title: 'čašica ukusa',
    photos: [
      '/galerija/casica-ukusa/crop/IGI_5781.jpg',
      '/galerija/casica-ukusa/crop/IGI_5794.jpg',
      '/galerija/casica-ukusa/crop/IGI_5844.jpg',
      '/galerija/casica-ukusa/crop/IGI_5928.jpg',
      '/galerija/casica-ukusa/crop/IGI_5977.jpg',
      '/galerija/casica-ukusa/crop/IGI_5991.jpg',
      '/galerija/casica-ukusa/crop/IGI_6005.jpg',
      '/galerija/casica-ukusa/crop/IGI_6112.jpg',
      '/galerija/casica-ukusa/crop/IGI_6119.jpg',
      '/galerija/casica-ukusa/crop/IGI_6120.jpg',
      '/galerija/casica-ukusa/crop/IGI_6124.jpg',
      '/galerija/casica-ukusa/crop/IGI_6135.jpg',
      '/galerija/casica-ukusa/crop/MSP_7252.jpg',
      '/galerija/casica-ukusa/crop/MSP_7261.jpg',
      '/galerija/casica-ukusa/crop/MSP_7402.jpg',
      '/galerija/casica-ukusa/crop/MSP_7420.jpg',
      '/galerija/casica-ukusa/crop/001.jpg',
      '/galerija/casica-ukusa/crop/004.jpg',
      '/galerija/casica-ukusa/crop/IGI_3507.jpg',
      '/galerija/casica-ukusa/crop/IGI_4201.jpg',
      '/galerija/casica-ukusa/crop/IGI_6216.jpg',
      '/galerija/casica-ukusa/crop/IGI_6329.jpg',
    ],
  },
  {
    album: 'duh-dorcola',
    title: 'duh dorćola',
    photos: [
      '/galerija/duh-dorcola/crop/IGI_2831.jpg',
      '/galerija/duh-dorcola/crop/IGI_2833.jpg',
      '/galerija/duh-dorcola/crop/IGI_2858.jpg',
      '/galerija/duh-dorcola/crop/IGI_2886.jpg',
      '/galerija/duh-dorcola/crop/IGI_2891.jpg',
      '/galerija/duh-dorcola/crop/IGI_2892.jpg',
      '/galerija/duh-dorcola/crop/IGI_2905.jpg',
      '/galerija/duh-dorcola/crop/IGI_2906.jpg',
      '/galerija/duh-dorcola/crop/IGI_2911.jpg',
      '/galerija/duh-dorcola/crop/IGI_2916.jpg',
      '/galerija/duh-dorcola/crop/IGI_2923.jpg',
      '/galerija/duh-dorcola/crop/IGI_2924.jpg',
      '/galerija/duh-dorcola/crop/IGI_3045.jpg',
      '/galerija/duh-dorcola/crop/IGI_3061.jpg',
      '/galerija/duh-dorcola/crop/IGI_3096.jpg',
      '/galerija/duh-dorcola/crop/IGI_3125.jpg',
      '/galerija/duh-dorcola/crop/IGI_3127.jpg',
      '/galerija/duh-dorcola/crop/Photo_Alex_Dmitrovic_0088.jpg',
      '/galerija/duh-dorcola/crop/Photo_Alex_Dmitrovic_0108.jpg',
      '/galerija/duh-dorcola/crop/Photo_Alex_Dmitrovic_0116.jpg',
      '/galerija/duh-dorcola/crop/Photo_Alex_Dmitrovic_0150.jpg',
    ]
  },
  {
    album: 'kuca-prvoklasnog-uzitka',
		title: 'kuća prvoklasnog užitka',
    photos: [
      '/galerija/kuca-prvoklasnog-uzitka/crop/IGI_5868.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/IGI_5869.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/IGI_5872.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/IGI_5875.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/IGI_5877.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/IGI_5878.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/IGI_6009.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/IGI_6017.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/IGI_6029.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/IGI_6031.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/IGI_6412.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/IGI_6428.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/IGI_6443.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/MSP_7173.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/MSP_7174.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/MSP_7175.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/MSP_7177.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/MSP_7178.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/MSP_7180.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/MSP_7286.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/MSP_7354.jpg',
      '/galerija/kuca-prvoklasnog-uzitka/crop/MSP_7357.jpg',
    ]
  },
  {
    album: 'vas-dan-na-nas-nacin',
		title: 'vaš dan na naš način',
    photos: [
      "/galerija/vas-dan-na-nas-nacin/crop/123.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/24535.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/252.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/3656.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/4564.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/456.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/5675.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/7868.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/876678.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/888.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/997.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0002 NR3_9199.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0008 NR3_9207.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0030 NR3_9242_resized.JPG",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0043 NR3_9261_resized.JPG",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0071 NR3_9328_resized.JPG",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0088 NR3_9362_resized.JPG",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0116 NR3_9425_resized.JPG",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0138 NR3_9485.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0178 NR3_9570.JPG",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0219 NR3_9650_resized.JPG",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0236 NR3_9689.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0248 NR3_9739_resized.JPG",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0255 NR3_9756_resized.JPG",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0267 NR3_9802_resized.JPG",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0276 NR3_9818_resized.JPG",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0279 NR3_9826.jpg",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0317 NR3_9910_resized.JPG",
      "/galerija/vas-dan-na-nas-nacin/crop/www.nikolarudic.com 20201103 0321 NR3_9927_resized.JPG",
    ]
  }
]

