import * as React from "react"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import GalleryPhoto from "../components/GalleryPhoto"
import { galleryContent as GalleryContent } from "../components/GalleryContent"
import GalleryLightbox from "../components/GalleryLightbox"
import { SEO } from "../components/seo"

const GalerijaPage = () => {

  const [ activeTab, setActiveTab ] = React.useState(0)
  const [ activePhoto, setActivePhoto ] = React.useState(false)

  return (
    <Layout
      backArrow={true}
      bodyClasses={" flat-menu-page -white-third-bg meny-right "}
    >
      <Breadcrumbs currentPage="Galerija" />
      <main className="main-content">
        <div className="container">
          <h1 className="title-of-page">Galerija</h1>

          <div className="main-gallery-section -bottom-indent">
            <div className="row">
              <div className="col-12">
                <div className="description-text intro-text">
                  <p style={{textAlign: 'center'}}>Slike možda govore hiljadu reči, ali naše samo jednu - DOBRODOŠLI!</p>
                </div>
              </div>
            </div>

            <ul className="nav page-tabs-nav justify-content-center" role="tablist">
							{GalleryContent.map((album, i) => (
									<li>
										<a onClick={(e) => { e.preventDefault(); setActiveTab(i)}} className={`tab-nav-item ${activeTab === i && " active"}`} id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected={`${activeTab === 1 ? " true" : "false"}`}>
											{album.title}
										</a>
									</li>
								)
							)}
            </ul>

            <div className="tab-content main-gallery-tabs-wrap -bottom-indent">
							{GalleryContent.map((album, i) => (
									<div className={`tab-pane fade h-100 gallery-wrap ${activeTab === i && " active show"}`} id="all" role="tabpanel" aria-labelledby="all-tab">
										<div className="row small-gutters-fix">
											{GalleryContent[i].photos.map((photo, j) => (
													<GalleryPhoto photoIndex={j} photo={photo} setActivePhoto={setActivePhoto} />
												)
											)}
										</div>
									</div>
								)
							)}
            </div>

          </div>
        </div>
      </main>

			<div className="lightbox-wrapper">
			{ activePhoto !== false && (
				<GalleryLightbox tab={activeTab} photo={activePhoto} setActivePhoto={setActivePhoto} />
			) }
			</div>

    </Layout>
  )
}

export default GalerijaPage

export const Head = () => (
  <SEO />
)
